<template>
  <v-card
    class="pa-4"
    color="#efefef"
    elevation="0"
  >
    <template v-if="!success">
      <div style="display: grid;grid-template-columns: repeat(2, 1fr); grid-column-gap: 16px">
        <div class="flex-grow-1">
          <div class="pa-1">
            <date-range-picker
              v-model="dates"
            />
          </div>

          <div
            v-if="dates"
            class="mt-3 mr-1 pa-1"
          >
            <h3>
              {{ $t('chooseOtherConditions') }}
            </h3>
            <v-btn
              class="mt-2"
              elevation="0"
              style="color: #3063E9; border: 1px solid darkgrey"
              @click="showSelectFilterDialog=true"
            >
              <v-icon left>
                mdi-calendar
              </v-icon>
              {{ $t('otherSelect') }}
            </v-btn>
          </div>

          <div
            v-if="dates && !selectFilterIsBlank"
            class="mt-1"
          >
            <h3>{{ $t('alreadyChooseSelect') }}</h3>
            <div
              v-if="selectFilter.selectCategoryTypeIds.length>0"
              class="ml-1 my-2"
              style="background-color: darkgrey; border-radius: 8px;"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              {{ selectFilter.selectCategoryTypeIds.length || 1 }} x {{ $t('dishCategoryType') }}
            </div>

            <div
              v-if="selectFilter.selectCategoryIds.length>0"
              class="ml-1 my-2"
              style="background-color: darkgrey; border-radius: 8px;"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              {{ selectFilter.selectCategoryIds.length || 1 }} x {{ $t('dishCategory') }}
            </div>

            <div
              v-if="selectFilter.selectHideAttributeIds.length>0"
              class="ml-1 my-2"
              style="background-color: darkgrey; border-radius: 8px;"
            >
              <v-icon left>
                mdi-check
              </v-icon>
              {{ selectFilter.selectHideAttributeIds.length || 1 }} x  {{ $t('hiddenDishAttribute') }}
            </div>
          </div>

          <v-dialog
            v-model="showSelectFilterDialog"
            max-width="300px"
            persistent
          >
            <v-card
              class="pa-4"
            >
              <div class="text-overline">
                {{ $t('chooseNeedInclude') }}{{ $t('dishCategoryType') }}
              </div>
              <div class="mr-2">
                <v-select
                  v-model="selectFilter.selectCategoryTypeIds"
                  :items="dishCategoryTypeList"
                  :menu-props="{overflowY: true, outlined: true, contentClass: 'elevation-2 ikRounded', nudgeBottom: '64px'}"
                  :placeholder="$t('dishCategoryType')"
                  item-text="name"
                  item-value="id"
                  multiple
                  outlined
                  @click:clear="$emit('clear')"
                >
                  <template
                    #selection=" {item, index}"
                  >
                    <span
                      v-if="index == 0"
                      class="grey--text text-caption"
                    >
                      {{ $t('dishCategoryType') }} (+{{ selectFilter.selectCategoryTypeIds.length || 1 }})
                    </span>
                  </template>
                </v-select>
              </div>

              <div
                class="text-overline"
              >
                {{ $t('chooseNeedInclude') }}{{ $t('dishCategory') }}
              </div>
              <v-select
                v-model="selectFilter.selectCategoryIds"
                :items="dishCategoryList"
                :menu-props="{overflowY: true, outlined: true, contentClass: 'elevation-2 ikRounded', nudgeBottom: '64px'}"
                :placeholder="$t('dishCategory')"
                item-text="name"
                item-value="id"
                multiple
                outlined
                @click:clear="$emit('clear')"
              >
                <template
                  #selection=" {item, index}"
                >
                  <span
                    v-if="index == 0"
                    class="grey--text text-caption"
                  >
                    {{ $t('dishCategory') }} (+{{ selectFilter.selectCategoryIds.length || 1 }})
                  </span>
                </template>
              </v-select>

              <div class="text-overline">
                {{ $t('chooseNotNeedInclude') }}{{ $t('dishAttribute') }}
              </div>
              <v-select
                v-model="selectFilter.selectHideAttributeIds"
                :items="hideAttributeList"
                :menu-props="{overflowY: true, outlined: true, contentClass: 'elevation-2 ikRounded', nudgeBottom:'64px'}"
                :placeholder="$t('dishAttribute')"
                item-text="name"
                item-value="id"
                multiple
                outlined
                @click:clear="$emit('clear')"
              >
                <template
                  #selection=" {item, index}"
                >
                  <span
                    v-if="index == 0"
                    class="grey--text text-caption"
                  >
                    {{ $t('dishAttribute') }} (+{{ selectFilter.selectHideAttributeIds.length || 1 }})
                  </span>
                </template>
              </v-select>

              <div>
                <v-btn
                  block
                  class="mb-2"
                  outlined
                  style="background-color: white; color: black"
                  @click="handleClear"
                >
                  {{ $t('cleanSelect') }}
                </v-btn>

                <v-btn
                  block
                  class="primary"
                  color="white"
                  outlined
                  @click="showSelectFilterDialog=false"
                >
                  {{ $t('sure') }}
                </v-btn>
              </div>
            </v-card>
          </v-dialog>
        </div>

        <div
          v-if="dates"
          class="pa-1 flex-grow-1"
        >
          <div class="d-flex align-center">
            <h3>{{ $t('searchResults') }}</h3>
          </div>
          <p class="my-2">
            {{ $t('numberOfDishes', { filterListLength: filterDishStatisticList.length}) }}
          </p>
          <div
            class="mr-4"
          >
            <v-btn
              :disabled="filterDishStatisticList.length<=0"
              :loading="loading"
              block
              color="primary"
              elevation="0"
              @click="printOrSendMail('sendToMail')"
            >
              {{ $t('sendToZbon', { zbonEmail: zbonEmail}) }}
            </v-btn>
            <v-btn
              :disabled="filterDishStatisticList.length<=0"
              :loading="loading"
              block
              class="mt-4"
              color="primary"
              elevation="0"
              @click="printOrSendMail('printWithCode')"
            >
              {{ $t('printByDishNumber') }}
            </v-btn>

            <v-btn
              :disabled="filterDishStatisticList.length<=0"
              :loading="loading"
              block
              class="mt-4"
              color="primary"
              elevation="0"
              @click="printOrSendMail('print')"
            >
              {{ $t('print') }}
            </v-btn>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <div style="height: 400px">
        <h3>{{ $t('success') }}</h3>
        <p>{{ $t('need30seconds') }}</p>
        <v-btn
          color="primary"
          outlined
          @click="reset"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          {{ $t('returnToHomeAndExportOther') }}
        </v-btn>
      </div>
    </template>
    <v-dialog
      v-model="showDateMenu"
      max-width="400px"
    >
      <v-card
        class="pa-2"
        color="#efefef"
        @click="showDateMenu=false"
      >
        <date-range-picker
          v-model="dates"
        />
      </v-card>
    </v-dialog>
  </v-card>
</template>

<script>
  import DateRangePicker from '@/composable/DateRangePicker'
  import DishStatistic from '@/model/export/DishStatistic'
  import { getNiceRestaurantInfo, isBlank } from '@/common/Utlis/api'
  import Category from '@/model/dish/Category'
  import CategoryType from '@/model/dish/CategoryType'
  import Attribute from '@/model/dish/Attribute'
  import GlobalConfig from '@/GlobalSettings'
  import { getNiceLabel } from '@/common/Repository/DateRepository'
  import AadenSelect from '@/views/AadenPages/export/childComponent/AadenSelect'
  import IKUtils from 'innerken-js-utils'

  export default {
    name: 'DishStatistic',
    components: {
      AadenSelect,
      DateRangePicker,
    },
    data () {
      return {
        dates: null,
        dishStatisticList: [],
        model: DishStatistic,
        CategoryModel: Category,
        CategoryTypeModel: CategoryType,
        AttributeModel: Attribute,
        zbonEmail: '',
        loading: false,
        success: false,
        selectFilter: {
          selectCategoryIds: [],
          selectCategoryTypeIds: [],
          selectHideAttributeIds: [],
        },
        dishCategoryList: [],
        dishCategoryTypeList: [],
        hideAttributeList: [],
        showDateMenu: false,
        showSelectFilterDialog: false,
      }
    },
    computed: {
      filterDishStatisticList () {
        // console.log('this.selectFilter', Object.entries(this.selectFilter))

        if (this.selectFilterIsBlank) {
          return this.dishStatisticList
        } else {
          return this.dishStatisticList
            .filter(item =>
              this.selectFilter.selectCategoryIds?.includes(item.categoryId) &&
              this.selectFilter.selectCategoryTypeIds?.includes(item.categoryTypeId) &&
              !this.selectFilter.selectHideAttributeIds?.includes(item.id),
            ) ?? []
        }
      },
      selectFilterIsBlank () {
        return Object.entries(this.selectFilter).every(i => isBlank(i[1]))
      },
    },
    watch: {
      dates: {
        immediate: true,
        handler (val) {
          if (val) {
            this.loadList()
          }
        },
      },
    },
    async mounted () {
      this.zbonEmail = (await getNiceRestaurantInfo()).ZBonEmail
      this.success = false

      this.dishCategoryList = (await this.CategoryModel.load()).map(item => {
        const name = item.langs.filter(i => i.lang === GlobalConfig.lang)[0].name
        return {
          id: item.id,
          name: name,
        }
      })
      this.dishCategoryTypeList = (await this.CategoryTypeModel.load()).map(item => {
        return {
          id: item.id,
          name: item.name,
        }
      })
      this.hideAttributeList = (await this.AttributeModel.load()).map(item => {
        return {
          id: item.id,
          name: item.name,
        }
      })

      this.selectFilter.selectCategoryIds = JSON.parse(localStorage.getItem('dishCategoryIds'))
      this.selectFilter.selectCategoryTypeIds = JSON.parse(localStorage.getItem('dishCategoryTypeIds'))
      this.selectFilter.selectHideAttributeIds = JSON.parse(localStorage.getItem('hideAttributeIds'))
    },
    methods: {
      getNiceLabel,
      reset () {
        this.success = false
        this.loading = false
      },
      async loadList () {
        this.loading = true
        this.dishStatisticList = await this.model.load(...this.dates)
        this.loading = false
      },
      async printOrSendMail (op) {
        this.loading = true

        const filter = [
          this.selectFilter.selectCategoryIds?.toString(),
          this.selectFilter.selectCategoryTypeIds?.toString(),
          this.selectFilter.selectHideAttributeIds?.toString(),
        ]

        try {
          if (op === 'print') {
            await this.model.printOrSendMail('printSalesBon', this.dates, ...filter)
          } else if (op === 'printWithCode') {
            await this.model.printOrSendMail('printSalesBonByDishCode', this.dates, ...filter)
          } else {
            await this.model.printOrSendMail('sendSalesBonEmail', this.dates, ...filter)
          }
        } catch
          (e) {
          IKUtils.showError(e)
        }

        localStorage.setItem('dishCategoryIds', JSON.stringify(this.selectFilter.selectCategoryIds))
        localStorage.setItem('dishCategoryTypeIds', JSON.stringify(this.selectFilter.selectCategoryTypeIds))
        localStorage.setItem('hideAttributeIds', JSON.stringify(this.selectFilter.selectHideAttributeIds))

        this.success = true
        this.loading = false
      },

      handleClear () {
        this.showSelectFilterDialog = false
        this.dates = null
        this.selectFilter = {
          selectCategoryIds: [],
          selectCategoryTypeIds: [],
          selectHideAttributeIds: [],
        }
        localStorage.setItem('dishCategoryIds', null)
        localStorage.setItem('dishCategoryTypeIds', null)
        localStorage.setItem('hideAttributeIds', null)
      },

    },

  }
</script>

<style scoped>

</style>
