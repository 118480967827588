<template>
  <v-card
    class="pa-4"
    color="#efefef"
    elevation="0"
  >
    <template v-if="!success">
      <div
        style="display: grid;grid-template-columns: repeat(2,1fr)"
      >
        <date-range-picker
          v-model="dates"
          class="mr-4"
        />
        <div
          v-if="dates"
          class="pa-1 flex-grow-1"
        >
          <div class="d-flex align-center">
            <h3>{{ $t('export') }}</h3>
          </div>
          <p class="mt-4">
            {{ $t('willExport', {getNiceLabel: getNiceLabel(dates)}) }}
          </p>
          <a ref="downloadfile"/>
          <v-btn
            :loading="loading"
            block
            color="primary"
            elevation="0"
            @click="sendToMail"
          >
            {{ $t('sendTo', {zbonEmail: zbonEmail}) }}
          </v-btn>
          <v-btn
            :loading="loading"
            block
            class="mt-2"
            color="primary"
            elevation="0"
            @click="exportCSV"
          >
            {{ $t('downloadNow') }}
          </v-btn>
        </div>
      </div>
    </template>
    <template v-else>
      <div style="height: 300px">
        <h3>{{ $t('success') }}</h3>
        <p class="mt-3">
          {{ $t('need30secondcheckJunk') }}
        </p>
        <v-btn
          color="primary"
          outlined
          @click="reset"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          {{ $t('returnToHomeAndExportOther') }}
        </v-btn>
      </div>
    </template>
  </v-card>
</template>

<script>
  import { exportCSVFromTo, getNiceRestaurantInfo, sendCsvToEmail } from '@/common/Utlis/api'
  import DateRangePicker from '@/composable/DateRangePicker'
  import { getNiceLabel } from '@/common/Repository/DateRepository'

  export default {
    name: 'GDPdUCSVExport',
    components: { DateRangePicker },
    data: function () {
      return {
        dates: null,
        zbonEmail: '',
        loading: false,
        success: false,
      }
    },
    watch: {
      dates: {
        immediate: true,
        handler (val) {
          if (val) {
          }
        },
      },
    },
    async mounted () {
      this.zbonEmail = (await getNiceRestaurantInfo()).ZBonEmail
      this.success = false
    },
    methods: {
      getNiceLabel,
      reset () {
        this.success = false
        this.loading = false
        this.dates = null
      },
      async sendToMail () {
        this.loading = true
        await sendCsvToEmail(
          this.dates[0] + ' 04:00:00',
          this.dates[1] + ' 04:00:00',
        )
        this.loading = false
        this.success = true
      },
      async exportCSV () {
        this.loading = true
        const data = await exportCSVFromTo({
          startTime: this.dates[0] + ' 04:00:00',
          endTime: this.dates[1] + ' 04:00:00',
        })
        const content = new Blob([data], { type: 'application/zip' })
        const urlObj = window.URL
        const url = urlObj.createObjectURL(content)
        this.$refs.downloadfile.href = url
        this.$refs.downloadfile.acceptCharset = 'UTF-8'
        this.$refs.downloadfile.click()
        urlObj.revokeObjectURL(url)
        this.loading = false
        this.success = true
      },

    },
  }
</script>

<style scoped>

</style>
