<template>
  <v-card
    class="pa-4"
    color="#efefef"
    elevation="0"
  >
    <template v-if="!success">
      <div style="display: grid;grid-template-columns: repeat(2,1fr)">
        <date-range-picker
          v-model="dates"
          class="mr-4"
        />
        <div
          v-if="dates"
          class="pa-1 flex-grow-1"
        >
          <div class="d-flex align-center">
            <h3>{{ $t('searchResult') }}</h3>
          </div>
          <p class="mt-4">
            {{ $t('thereAreZBons', {length: zbonList.length}) }}
          </p>
          <v-btn
            :disabled="zbonList.length===0"
            :loading="loading"
            block
            color="primary"
            elevation="0"
            @click="outputPdf"
          >
            {{ $t('sendTo', {zbonEmail: zbonEmail}) }}
          </v-btn>
          <v-btn
            :disabled="zbonList.length===0"
            :loading="loading"
            block
            class="mt-2"
            color="primary"
            elevation="0"
            @click="reprintZbon"
          >
            {{ $t('reprint') }}
          </v-btn>
        </div>
      </div>
    </template>
    <template v-else>
      <div style="height: 400px">
        <h3>{{ $t('success') }}</h3>
        <p class="mt-4">
          {{ $t('exportedCountZBon', {length: zbonList.length}) }}
        </p>
        <p>{{ $t('need30seconds') }}</p>
        <v-btn
          color="primary"
          outlined
          @click="reset"
        >
          <v-icon left>
            mdi-arrow-left
          </v-icon>
          {{ $t('returnToHomeAndExportOther') }}
        </v-btn>
      </div>
    </template>
  </v-card>
</template>

<script>
  import DateRangePicker from '@/composable/DateRangePicker'
  import { loadZBonList } from '@/common/common'
  import Zbon from '@/model/export/Zbon'
  import { getNiceRestaurantInfo, reprintZbon } from '@/common/Utlis/api'

  export default {
    name: 'ZbonExport',
    components: { DateRangePicker },
    data: function () {
      return {
        dates: null,
        zbonList: [],
        model: Zbon,
        zbonEmail: '',
        loading: false,
        success: false,
      }
    },
    watch: {
      dates: {
        immediate: true,
        handler (val) {
          if (val) {
            this.loadZBonList()
          }
        },
      },
    },
    async mounted () {
      this.zbonEmail = (await getNiceRestaurantInfo()).ZBonEmail
      this.success = false
    },
    methods: {
      reset () {
        this.success = false
        this.loading = false
        this.dates = null
        this.zbonList = []
      },
      async loadZBonList () {
        this.zbonList = await loadZBonList(...this.dates)
        console.log(this.zbonList)
      },
      async reprintZbon () {
        this.loading = true
        const selectedItemIds = this.zbonList.map(it => it.id)
        try {
          for (const i of selectedItemIds) {
            await reprintZbon(i)
          }
        } catch (e) {

        }
        this.success = true
        this.loading = false
      },
      async outputPdf () {
        this.loading = true
        const selectedItemIds = this.zbonList.map(it => it.id)
        try {
          await this.model.outputMultiplePDF(selectedItemIds)
        } catch (e) {

        }
        this.success = true
        this.loading = false
      },
    },
  }
</script>

<style scoped>

</style>
